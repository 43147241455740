/* eslint-disable @typescript-eslint/no-floating-promises */
import { GenericModal, useModal } from '@app/components/GenericModal';
import { useAuth } from '@app/hooks/useAuth';
import { deleteStudent, StudentData } from '@app/services/studentsService';
import { Delete, Edit, Key, MoreVert } from '@mui/icons-material';
import {
  ButtonBase,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ResetPasswordFormModal } from '@app/components/ResetPasswordFormModal';
import { ConfirmDeleteModal } from '@app/components/ConfirmDeleteModal';
import { EditForm } from './EditForm';

interface Props {
  data: StudentData;
}

export function TableRowActions({ data }: Props) {
  // ----------------- menu state -----------------

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // eslint-disable-next-line unicorn/no-useless-undefined
    setAnchorEl(undefined);
  };

  // ----------------- modal states -----------------
  const {
    isOpen: resetPasswordIsOpen,
    openModal: openResetPasswordModal,
    closeModal: closeResetPasswordModal,
  } = useModal();

  const {
    isOpen: editStudentIsOpen,
    openModal: openEditStudentModal,
    closeModal: closeEditStudentModal,
  } = useModal();

  const {
    isOpen: deleteStudentIsOpen,
    openModal: openDeleteStudentModal,
    closeModal: closeDeleteStudentModal,
  } = useModal();

  useEffect(() => {
    handleClose();
  }, [resetPasswordIsOpen, editStudentIsOpen, deleteStudentIsOpen]);

  // ----------------- actions -----------------
  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  return (
    <>
      <ButtonBase onClick={handleClick}>
        <MoreVert />
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'student-options-menu',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'center',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <MenuItem onClick={openEditStudentModal}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem onClick={openResetPasswordModal}>
          <ListItemIcon>
            <Key />
          </ListItemIcon>
          <ListItemText primary="Change Password" />
        </MenuItem>
        <MenuItem onClick={openDeleteStudentModal} sx={{ color: 'red' }}>
          <ListItemIcon>
            <Delete sx={{ color: 'red' }} />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>

      <ResetPasswordFormModal
        token={token}
        userId={data.id}
        name={`${data.firstName} ${data.lastName}`}
        open={resetPasswordIsOpen}
        onClose={closeResetPasswordModal}
      />
      <GenericModal
        title="Edit Student"
        isOpen={editStudentIsOpen}
        onClose={closeEditStudentModal}
        useOnlyCloseButton
      >
        <EditForm
          onClose={closeEditStudentModal}
          userId={data.id}
          token={token}
          defaults={data}
        />
      </GenericModal>
      <ConfirmDeleteModal
        open={deleteStudentIsOpen}
        onClose={closeDeleteStudentModal}
        text={`${data.customId} - ${data.firstName} ${data.lastName}`}
        deleteFn={() => deleteStudent({ token, id: data.id })}
        queryKey="students"
      />
    </>
  );
}
