import { ContentCopy, Download } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import QRCode from 'react-qr-code';

interface Props {
  id: string;
  value: string;
}
export function QrGenerator({ id, value }: Props) {
  const onImageDownload = () => {
    const svg = document.querySelector(`#${id}`);
    if (!svg) return;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    const img = new Image();

    img.addEventListener('load', () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'QRCode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    });
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const onImageCopy = () => {
    const svg = document.querySelector(`#${id}`);
    if (!svg) return;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    const img = new Image();

    img.addEventListener('load', () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      canvas.toBlob(blob => {
        if (blob) {
          const item = new ClipboardItem({ 'image/png': blob });
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          navigator.clipboard.write([item]);
        }
      });
    });
    img.src = `data:image/svg+xml;base64,${window.btoa(svgData)}`;
  };

  return (
    <Stack alignItems="center" spacing={2}>
      <QRCode id={id} value={value} size={200} />
      <Stack direction="row" justifyContent="center" spacing={2}>
        <Button
          variant="contained"
          onClick={onImageDownload}
          startIcon={<Download />}
          color="primary"
          size="small"
        >
          Download
        </Button>
        <Button
          variant="contained"
          onClick={onImageCopy}
          startIcon={<ContentCopy />}
          color="warning"
          size="small"
        >
          Copy
        </Button>
      </Stack>
    </Stack>
  );
}
