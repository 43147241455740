import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { useAuth } from '@app/hooks/useAuth';
import { Actions } from './Actions';
import { ListView } from './ListView';

export function FinacialLiteracy() {
  const { isAdmin } = useAuth();

  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Dashboard',
            link: '/',
          },
          {
            label: 'Financial Literacy',
            link: '/financial-literacy',
          },
        ]}
      />
      {isAdmin && <Actions />}
      <ListView />
    </Layout>
  );
}
