import {
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  CardActionArea,
  Alert,
  Box,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Path } from 'react-hook-form';
import { get as lodashGet } from 'lodash';

import { SortBy } from './DataWrapper';
//  Interface for TableHeader
export interface TableHeader<T> {
  headerLabel: string;
  key: Path<T>;
  isSortable: boolean;
  renderHeader?: (tableHeader: TableHeader<T>) => React.ReactNode;
  renderCell?: (row: T) => React.ReactNode;
}

//  Interface for Props for GenericTable
interface Props<T> {
  tableHeaders: TableHeader<T>[];
  data: T[];
  sortBy?: SortBy<T>;
  setSortBy: (sortBy: SortBy<T>) => void;
}

export function GenericTable<T>(props: Props<T>) {
  const { tableHeaders, data, sortBy, setSortBy } = props;
  const [rows, setRows] = useState<T[]>(data);

  //  Function to handleSortButton
  const handleSortButton = (tableHeaderName: Path<T>) => () => {
    let newDirection: 'asc' | 'desc' = 'desc';
    if (sortBy === undefined || sortBy?.direction === 'desc') {
      newDirection = 'asc';
    }
    setSortBy({ field: tableHeaderName, direction: newDirection });
  };

  //  Update rows state when the data has been updated
  useEffect(() => {
    setRows(data);
  }, [data]);

  //  Function to render table header
  function renderTableHeader(tableHeader: TableHeader<T>) {
    const isSorted =
      tableHeader.isSortable && sortBy?.field === tableHeader.key;
    return (
      <TableCell key={tableHeader.headerLabel} sx={{ p: 0 }}>
        <CardActionArea
          onClick={handleSortButton(tableHeader.key)}
          disabled={!tableHeader.isSortable}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            p: 2,
            bgcolor: isSorted ? '#e1e1e1' : 'initial',
          }}
        >
          {tableHeader.renderHeader ? (
            tableHeader.renderHeader(tableHeader)
          ) : (
            <Typography>{tableHeader.headerLabel}</Typography>
          )}
          {isSorted &&
            (sortBy?.direction === 'desc' ? (
              <KeyboardArrowDown />
            ) : (
              <KeyboardArrowUp />
            ))}
        </CardActionArea>
      </TableCell>
    );
  }

  //  Function to render table cell
  function renderTableCell(tableHeader: TableHeader<T>, row: T) {
    return (
      <TableCell key={tableHeader.headerLabel} sx={{ px: 2, py: 1.5 }}>
        {tableHeader.renderCell ? (
          tableHeader.renderCell(row)
        ) : (
          <Typography>{lodashGet(row, tableHeader.key)}</Typography>
        )}
      </TableCell>
    );
  }

  return (
    <Box>
      {rows.length === 0 ? (
        <Alert
          severity="warning"
          icon={<InfoOutlinedIcon color="primary" />}
          sx={{
            marginLeft: 4,
            marginRight: 4,
            justifyContent: 'center',
            color: '#7E0000',
            fontWeight: 'bold',
          }}
        >
          No Records Found !
        </Alert>
      ) : (
        <TableContainer>
          <Table aria-label="table">
            <TableHead>
              <TableRow sx={{ bgcolor: '#F2F2F6' }}>
                {tableHeaders.map(tableHeader =>
                  renderTableHeader(tableHeader),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={i}>
                  {tableHeaders.map(tableHeader =>
                    renderTableCell(tableHeader, row),
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
