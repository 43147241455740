import { ChevronRight } from '@mui/icons-material';
import { Breadcrumbs, Typography, Link } from '@mui/material';
import { Link as RLink } from 'react-router-dom';

interface Props {
  links: {
    label: string;
    link: string;
  }[];
}
export function BreadCrumbs({ links }: Props) {
  const firsts = links.slice(0, -1);
  const last = links[links.length - 1];

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<ChevronRight />}
      sx={{ mb: 2 }}
    >
      {firsts.map(({ label, link }) => (
        <Link component={RLink} to={link} key={label} fontWeight="bold">
          {label}
        </Link>
      ))}
      <Typography fontWeight="bold">{last.label}</Typography>
    </Breadcrumbs>
  );
}
