import { DataWrapper } from '@app/components/DataWrapper';
import { GenericTable, TableHeader } from '@app/components/GenericTable';
import { useAuth } from '@app/hooks/useAuth';
import { getLecturers, LecturerData } from '@app/services/lecturerService';
import { Avatar } from '@mui/material';
import { TableRowActions } from './TableRowActions';

const headers: TableHeader<LecturerData>[] = [
  {
    headerLabel: 'Image',
    isSortable: false,
    key: 'user.profilePicture',
    renderCell: row => (
      <Avatar
        src={row.user.profilePicture}
        alt={row.user.firstName}
        sx={{ height: 32, width: 32 }}
      />
    ),
  },
  {
    headerLabel: 'Title',
    key: 'title',
    isSortable: false,
  },
  {
    headerLabel: 'First Name',
    isSortable: true,
    key: 'user.firstName',
  },
  {
    headerLabel: 'Last Name',
    isSortable: true,
    key: 'user.lastName',
  },
  {
    headerLabel: 'Email',
    isSortable: true,
    key: 'user.email',
  },
  {
    headerLabel: 'Actions',
    isSortable: false,
    key: 'id',
    renderCell: row => <TableRowActions data={row} />,
  },
];

export function Tables() {
  const { isAdmin } = useAuth();

  return (
    <DataWrapper<LecturerData>
      id="professors"
      title="Professors"
      filterConfig={[
        {
          field: 'user.firstName',
          label: 'First Name',
          type: 'contains',
          value: '',
          isCaseInsensitive: true,
        },
        {
          field: 'user.lastName',
          label: 'Last Name',
          type: 'contains',
          value: '',
          isCaseInsensitive: true,
        },
        {
          field: 'user.email',
          label: 'Email',
          type: 'contains',
          value: '',
          isCaseInsensitive: true,
        },
      ]}
      // eslint-disable-next-line @typescript-eslint/require-await
      fetchData={({ token, options }) => getLecturers(token, options)}
      render={(data, sortBy, setSortBy) => (
        <GenericTable<LecturerData>
          tableHeaders={isAdmin ? headers : headers.slice(0, -1)}
          data={data}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      )}
    />
  );
}
