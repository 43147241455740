import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { Actions } from './Actions';
import { Table } from './Table';

export function Lectures() {
  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Dashboard',
            link: '/',
          },
          {
            label: 'Lectures',
            link: '/events/lectures',
          },
        ]}
      />
      <Actions />
      <Table />
    </Layout>
  );
}
