import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    fontFamily: "'Nunito Sans', sans-serif",
  },
  palette: {
    primary: {
      main: '#7E0000',
    },
  },
});
