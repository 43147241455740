import { ConfirmDeleteModal } from '@app/components/ConfirmDeleteModal';
import { GenericModal, useModal } from '@app/components/GenericModal';
import { useAuth } from '@app/hooks/useAuth';
import { deleteTutoring, TutoringData } from '@app/services/eventService';
import { Delete, Edit, QrCode } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { DetailModal } from './DetailsModal';
import { Form } from './Form';

interface Props {
  data: TutoringData;
}

export function TableRowActions({ data }: Props) {
  const {
    isOpen: isDetailsModalOpen,
    openModal: openDetailsModal,
    closeModal: closeDetailsModal,
  } = useModal();

  const {
    isOpen: isEditModalOpen,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModal();

  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  return (
    <>
      <Stack direction="row" spacing={1}>
        <IconButton onClick={openDetailsModal}>
          <QrCode />
        </IconButton>

        <IconButton onClick={openEditModal}>
          <Edit color="info" />
        </IconButton>

        <IconButton onClick={openDeleteModal}>
          <Delete color="error" />
        </IconButton>
      </Stack>

      <DetailModal
        open={isDetailsModalOpen}
        onClose={closeDetailsModal}
        data={data}
      />
      <GenericModal
        title="Edit Tutoring"
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        useOnlyCloseButton
      >
        <Form
          onClose={closeEditModal}
          token={token}
          id={data.id}
          defaultValues={data}
        />
      </GenericModal>
      <ConfirmDeleteModal
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        text={
          <>
            {data.name}
            <br />
            <Typography color="error" variant="body2">
              **All Point Entries linked with this record will be deleted.
            </Typography>
          </>
        }
        deleteFn={() => deleteTutoring({ token, id: data.id })}
        queryKey="tutorings"
      />
    </>
  );
}
