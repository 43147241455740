/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export interface AuthToken {
  id: string;
  email: string;
  token: string;
  type: 'ADMIN' | 'LECTURER';
}
interface AuthState {
  isAuth: boolean;
  tokenData?: AuthToken;
}

const tokenData = localStorage.getItem('token');
const initialState: AuthState = {
  isAuth: !!tokenData,
  tokenData: tokenData ? JSON.parse(tokenData) : undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginAction: (state, { payload }: { payload: AuthToken }) => {
      localStorage.setItem('token', JSON.stringify(payload));
      state.isAuth = true;
      state.tokenData = payload;
    },
    logoutAction: state => {
      localStorage.removeItem('token');
      state.isAuth = false;
      state.tokenData = undefined;
    },
  },
});

export const {
  reducer: authReducer,
  actions: { loginAction, logoutAction },
} = authSlice;
