import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { useAuth } from '@app/hooks/useAuth';
import { Actions } from './Actions';
import { Tables } from './Tables';

export function Professors() {
  const { isAdmin } = useAuth();

  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Dashboard',
            link: '/',
          },
          {
            label: 'Professors',
            link: '/users/professors',
          },
        ]}
      />
      {isAdmin && <Actions />}
      <Tables />
    </Layout>
  );
}
