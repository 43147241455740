import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { Actions } from './Actions';
import { Table } from './Table';

export function Tutorings() {
  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Dashboard',
            link: '/',
          },
          {
            label: 'Tutorings',
            link: '/events/tutorings',
          },
        ]}
      />
      <Actions />
      <Table />
    </Layout>
  );
}
