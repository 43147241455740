import { GenericModal } from '@app/components/GenericModal';
import { resetPasswordByAdmin } from '@app/services/authService';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useMutation } from 'react-query';

interface Props {
  token: string;
  userId: string;
  name: string;
  open: boolean;
  onClose: () => void;
}
export function ResetPasswordFormModal({
  token,
  userId,
  name,
  open,
  onClose,
}: Props) {
  return (
    <GenericModal
      isOpen={open}
      title={`Change Password of ${name}`}
      onClose={onClose}
      useOnlyCloseButton
    >
      <ResetPasswordForm onClose={onClose} token={token} userId={userId} />
    </GenericModal>
  );
}

interface ResetPasswordFormProps {
  token: string;
  userId: string;
  onClose: () => void;
}
function ResetPasswordForm({ onClose, token, userId }: ResetPasswordFormProps) {
  const [newPassword, setNewPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [error, setError] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };
  const handleChangeRetype = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRetypePassword(event.target.value);
  };

  const mutation = useMutation(resetPasswordByAdmin);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    if (newPassword === retypePassword) {
      mutation.mutate({ token, userId, newPassword });
      onClose();
    } else {
      setError('Passwords do not match');
    }
  };

  const displayError =
    error || (mutation.error && (mutation.error as Error).message);

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <TextField
          fullWidth
          required
          type="password"
          size="small"
          label="New Password"
          value={newPassword}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          required
          type="password"
          size="small"
          label="Retype Password"
          value={retypePassword}
          onChange={handleChangeRetype}
        />
        {displayError && <Typography color="error">*{displayError}</Typography>}
        <Stack
          direction="row"
          justifyContent="end"
          width={1}
          spacing={2}
          mt={2}
        >
          <Button variant="outlined" color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Change Password
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}
