/* eslint-disable @typescript-eslint/no-floating-promises */
import { Btn } from '@app/components/Btn';
import {
  ContentData,
  createBusinessLibrary,
  updateBusinessLibrary,
} from '@app/services/otherContentService';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

interface Props {
  token: string;
  onClose: () => void;
  id?: string;
  defaultValues?: ContentData;
}
export function Form({ token, onClose, id, defaultValues }: Props) {
  const [values, setValues] = useState({
    url: defaultValues?.url || '',
    title: defaultValues?.title || '',
    btnText: defaultValues?.btnText || '',
  });
  const handleChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues(prev => ({ ...prev, [field]: event.target.value }));
    };

  const queryClient = useQueryClient();
  const addMutation = useMutation(createBusinessLibrary, {
    onSuccess: () => {
      queryClient.invalidateQueries('business-library');
      onClose();
    },
  });

  const editMutation = useMutation(updateBusinessLibrary, {
    onSuccess: () => {
      queryClient.invalidateQueries('business-library');
      onClose();
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      ...values,
      description: 'Ignore',
      youtubeUrl: undefined,
      image: undefined,
    };
    if (id) {
      editMutation.mutate({ token, id, ...data });
    } else if (data.url) {
      addMutation.mutate({ token, ...data });
    }
  };

  const error = id ? editMutation.error : addMutation.error;
  const isLoading = editMutation.isLoading || addMutation.isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <TextField
          fullWidth
          required
          type="url"
          size="small"
          label="Youtube playlist URL"
          value={values.url}
          onChange={handleChange('url')}
        />
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Title"
          value={values.title}
          onChange={handleChange('title')}
        />
        <TextField
          fullWidth
          type="text"
          size="small"
          label="Button Text (Default: Watch)"
          value={values.btnText}
          onChange={handleChange('btnText')}
        />

        {error && (
          <Typography color="error">{(error as Error).message}</Typography>
        )}
        <Stack direction="row" justifyContent="end" width={1} spacing={2}>
          <Button variant="outlined" color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Btn
            variant="contained"
            color="primary"
            type="submit"
            isLoading={isLoading}
          >
            {id ? 'Update' : 'Create'}
          </Btn>
        </Stack>
      </Stack>
    </form>
  );
}
