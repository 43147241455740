/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  createSemester,
  getPointConfig,
  setActiveSemester,
  updatePointConfigEntries,
} from '@app/services/pointService';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth } from './useAuth';

interface Options {
  onSemesterCreateSuccess?: () => void;
  onSemesterUpdateSuccess?: () => void;
  onPointConfigUpdateSuccess?: () => void;
}

export function usePointConfig({
  onSemesterCreateSuccess,
  onSemesterUpdateSuccess,
  onPointConfigUpdateSuccess,
}: Options = {}) {
  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  const [entriesError, setEntriesError] = useState<string>('');
  const [semestersError, setSemestersError] = useState<string>('');
  const [semesterCreateError, setSemesterCreateError] = useState<string>('');

  const { data } = useQuery(['pointConfig', token], () =>
    getPointConfig({ token }),
  );

  const queryClient = useQueryClient();

  const mutationupdateEntries = useMutation(updatePointConfigEntries, {
    onMutate: () => setEntriesError(''),
    onSuccess: () => {
      queryClient.invalidateQueries('pointConfig');
      if (onPointConfigUpdateSuccess) onPointConfigUpdateSuccess();
    },
    onError: (error: Error) => setEntriesError(error.message),
  });

  const updateEntries = (entries: { [key: string]: number }) => {
    mutationupdateEntries.mutate({ token, entries });
  };

  const mutationSetSemester = useMutation(setActiveSemester, {
    onMutate: () => setSemestersError(''),
    onSuccess: () => {
      queryClient.invalidateQueries('pointConfig');
      if (onSemesterUpdateSuccess) onSemesterUpdateSuccess();
    },
    onError: (error: Error) => setSemestersError(error.message),
  });

  const setSemester = (id: string) => {
    mutationSetSemester.mutate({ token, id });
  };

  const mutationCreateSemester = useMutation(createSemester, {
    onMutate: () => setSemesterCreateError(''),
    onSuccess: () => {
      queryClient.invalidateQueries('pointConfig');
      if (onSemesterCreateSuccess) onSemesterCreateSuccess();
    },
    onError: (error: Error) => setSemesterCreateError(error.message),
  });

  const createNewSemester = (start: string, end: string) => {
    mutationCreateSemester.mutate({ token, start, end });
  };

  return {
    data,
    updateEntries,
    setSemester,
    createNewSemester,
    semestersError,
    entriesError,
    semesterCreateError,
  };
}
