import { useAuth } from '@app/hooks/useAuth';
import { usePointConfig } from '@app/hooks/usePointConfig';
import { PointConfigEntry, PointConfigType } from '@app/services/pointService';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface TextFieldRowProps {
  label: string;
  value: number;
  helperText: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
}
function TextFieldRow({
  label,
  value,
  helperText,
  onChange,
  readOnly,
}: TextFieldRowProps) {
  return (
    <Stack direction="row" alignItems="center" width={1} gap={2}>
      <Typography sx={{ width: 0.4, maxWidth: 350 }}>{label}</Typography>

      <TextField
        type="number"
        value={value === 0 ? '' : value}
        onChange={onChange}
        size="small"
        sx={{ width: 0.1, maxWidth: 200 }}
        inputProps={{
          readOnly,
        }}
        required
      />
      <Typography variant="body2" sx={{ width: 0.5 }}>
        {helperText}
      </Typography>
    </Stack>
  );
}

function processDefaultValues(defaultValues: PointConfigEntry[]) {
  const out: {
    [key: string]: Pick<PointConfigEntry, 'score' | 'id'>;
  } = {};
  defaultValues.forEach(entry => {
    out[entry.type] = {
      score: entry.score,
      id: entry.id,
    };
  });
  return out;
}

export function PointEntriesForm() {
  const { isAdmin } = useAuth();

  const [isDirty, setIsDirty] = useState(false);
  const { data, updateEntries, entriesError } = usePointConfig({
    onPointConfigUpdateSuccess: () => setIsDirty(false),
  });

  const [values, setValues] = useState(
    processDefaultValues(data!.pointConfigs),
  );
  const onChange =
    (type: PointConfigType) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues(prev => ({
        ...prev,
        [type]: {
          ...prev[type],
          score: Number(event.target.value),
        },
      }));
      setIsDirty(true);
    };

  useEffect(() => {
    if (data?.pointConfigs) {
      setValues(processDefaultValues(data.pointConfigs));
    }
  }, [data?.pointConfigs]);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    e.currentTarget.checkValidity();
    const out: { [key: string]: number } = {};
    Object.keys(values).forEach(key => {
      out[values[key].id] = values[key].score;
    });
    updateEntries(out);
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack gap={1}>
        <TextFieldRow
          label="Lecture Attendance Points"
          value={values.LECTURE_ATTENDANCE_POINTS.score}
          helperText="For each lecture attended"
          onChange={onChange('LECTURE_ATTENDANCE_POINTS')}
          readOnly={!isAdmin}
        />
        <TextFieldRow
          label="Lecture Attendance Points - Weekly Max"
          value={values.LECTURE_ATTENDANCE_WK_MAX.score}
          helperText="Max. obtainable attendence points per week"
          onChange={onChange('LECTURE_ATTENDANCE_WK_MAX')}
          readOnly={!isAdmin}
        />
        <TextFieldRow
          label="Lecture Attendance Bonus Points"
          value={values.BONUS_LECTURE_ATTENDANCE_POINTS.score}
          helperText="Weekly bonus for reaching 90% attendance"
          onChange={onChange('BONUS_LECTURE_ATTENDANCE_POINTS')}
          readOnly={!isAdmin}
        />

        <Box mb={2} />

        <TextFieldRow
          label="Tutoring Attendance"
          value={values.TUTORING_ATTENDANCE_POINTS.score}
          helperText="Points for each attendance"
          onChange={onChange('TUTORING_ATTENDANCE_POINTS')}
          readOnly={!isAdmin}
        />
        <TextFieldRow
          label="Tutoring Attendance - Weekly Max"
          value={values.TUTORING_ATTENDANCE_WK_MAX.score}
          helperText="Max. obtainable tutoring points per semeter"
          onChange={onChange('TUTORING_ATTENDANCE_WK_MAX')}
          readOnly={!isAdmin}
        />

        <Box mb={2} />

        <TextFieldRow
          label="Event Attendance Points"
          value={values.EVENT_ATTENDANCE_POINTS.score}
          helperText="Points for each event attended"
          onChange={onChange('EVENT_ATTENDANCE_POINTS')}
          readOnly={!isAdmin}
        />
        <TextFieldRow
          label="Event Attendance Points - Semester Max"
          value={values.EVENT_ATTENDANCE_SEM_MAX.score}
          helperText="Max. obtainable event points per semeter"
          onChange={onChange('EVENT_ATTENDANCE_SEM_MAX')}
          readOnly={!isAdmin}
        />

        <Box mb={2} />

        <TextFieldRow
          label="Financial aid points (1)"
          value={values.FINANCIAL_AID_BEFORE_START_POINTS.score}
          helperText="For completing before start of the semester"
          onChange={onChange('FINANCIAL_AID_BEFORE_START_POINTS')}
          readOnly={!isAdmin}
        />
        <TextFieldRow
          label="Financial aid points (2)"
          value={values.FINANCIAL_AID_PHASE_1_POINTS.score}
          helperText="For completing between 1st and 3rd weeks of the semester"
          onChange={onChange('FINANCIAL_AID_PHASE_1_POINTS')}
          readOnly={!isAdmin}
        />
        <TextFieldRow
          label="Financial aid points (3)"
          value={values.FINANCIAL_AID_PHASE_2_POINTS.score}
          helperText="For completing between 4th and 6th weeks of the semester"
          onChange={onChange('FINANCIAL_AID_PHASE_2_POINTS')}
          readOnly={!isAdmin}
        />

        <Box mb={2} />

        <TextFieldRow
          label="Daily Login points"
          value={values.STUDENT_PORTAL_LOGIN_POINTS.score}
          helperText="Points for each Login (can be obtained only once per day)"
          onChange={onChange('STUDENT_PORTAL_LOGIN_POINTS')}
          readOnly={!isAdmin}
        />
        <TextFieldRow
          label="Daily Login- Weekly Max"
          value={values.STUDENT_PORTAL_SEM_MAX.score}
          helperText="Max. obtainable login points per semester"
          onChange={onChange('STUDENT_PORTAL_SEM_MAX')}
          readOnly={!isAdmin}
        />
        {entriesError && <Typography color="error">{entriesError}</Typography>}
        {isAdmin && (
          <Button
            variant="contained"
            type="submit"
            disabled={!isDirty}
            sx={{ alignSelf: 'end' }}
          >
            Save
          </Button>
        )}
      </Stack>
    </form>
  );
}
