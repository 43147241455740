import { apiCall } from '@app/util/helpers';
import { PROFILE_UPDATE_URL, PROFILE_URL } from 'config';
import { toast } from 'react-toastify';

export interface UserData {
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  profilePicture?: string;
  id: number;
}

export async function getProfile(token: string): Promise<UserData> {
  return apiCall({
    url: PROFILE_URL,
    token,
  });
}

export async function updateProfile({
  token,
  firstName,
  lastName,
  displayName,
  profilePicture,
}: {
  token: string;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  profilePicture?: File;
}): Promise<void> {
  const formData = new FormData();
  if (displayName) formData.append('displayName', displayName);
  if (firstName) formData.append('firstName', firstName);
  if (lastName) formData.append('lastName', lastName);
  if (profilePicture) formData.append('profilePicture', profilePicture);

  const res = await fetch(PROFILE_UPDATE_URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  if (res.status === 200) {
    toast.success('Profile updated successfully');
    return;
  }
  throw new Error('Someting went wrong');
}
