import { Btn } from '@app/components/Btn';
import { useProfile } from '@app/hooks/useProfile';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';

interface FormProps {
  onClose: () => void;
}
function Form({ onClose }: FormProps) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [error, setError] = useState('');

  const { changePassword, changePasswordIsLoading } = useProfile({
    onChangePasswordSuccess: onClose,
    onChangePasswordError: err => setError(err.message),
  });

  const validate = () => {
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return false;
    }
    // eslint-disable-next-line unicorn/no-useless-undefined
    return true;
  };

  const onSave: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    setError('');
    if (e.currentTarget.checkValidity() && validate()) {
      changePassword({ oldPassword, newPassword });
    }
  };

  return (
    <form onSubmit={onSave}>
      <Card>
        <CardContent>
          <Typography variant="h6" fontWeight="bold" mb={2}>
            Change Password
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="password"
                size="small"
                label="Current Password"
                value={oldPassword}
                onChange={e => setOldPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="password"
                size="small"
                label="New Password"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="password"
                size="small"
                label="Confirm Password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
        <CardActions>
          <Stack direction="row" justifyContent="end" width={1}>
            <Button
              variant="outlined"
              color="primary"
              onClick={onClose}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Btn
              variant="contained"
              color="primary"
              type="submit"
              isLoading={changePasswordIsLoading}
            >
              Change Password
            </Btn>
          </Stack>
        </CardActions>
      </Card>
    </form>
  );
}

export function ChangePassword() {
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return (
    <>
      <Card variant="outlined">
        <CardContent sx={{ p: 4 }}>
          <Typography variant="h6" fontWeight="bold" mb={2}>
            Change Password
          </Typography>
          <Typography mb={2}>
            If you want to save a different password, click the &quot;Change
            Password.&quot;
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={openModal}
            fullWidth
            size="small"
          >
            Change Password
          </Button>
        </CardContent>
      </Card>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="change-password-form"
        aria-describedby="change-password-form"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            maxWidth: 'calc(100% - 32px)',
            p: '0!important',
          }}
        >
          <Form onClose={closeModal} />
        </Box>
      </Modal>
    </>
  );
}
