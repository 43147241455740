import { useAuth } from '@app/hooks/useAuth';
import { Button, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GenericModal } from './GenericModal';

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      // eslint-disable-next-line unicorn/prefer-spread
      .split('')
      // eslint-disable-next-line unicorn/prefer-code-point
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

function getTimeLeft() {
  const tokenData = localStorage.getItem('token');
  const token: string = tokenData ? JSON.parse(tokenData).token : undefined;
  const timeLeft = token ? parseJwt(token).exp * 1000 - Date.now() : -1;
  return timeLeft;
}

interface Props {
  children: JSX.Element;
}

export function CheckExpiredToken({ children }: Props) {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(getTimeLeft());

  useEffect(() => {
    const time = getTimeLeft();
    const interval = setInterval(() => {
      setTimeLeft(getTimeLeft());
    }, time);
    return () => clearInterval(interval);
  }, []);

  const isExpired = timeLeft < 0;
  if (isExpired) {
    localStorage.removeItem('token');
  }

  const onGoToLogin = () => {
    logout();
    navigate('/login');
  };

  return (
    <>
      {children}
      {isExpired && (
        <GenericModal title="Session Has Expired" isOpen={isExpired}>
          <Typography>
            Your session has expired. Please log in again.
          </Typography>
          <Stack direction="row" justifyContent="end" width={1} gap={2}>
            <Button
              key="1"
              variant="contained"
              color="primary"
              onClick={onGoToLogin}
            >
              Go to Login
            </Button>
          </Stack>
        </GenericModal>
      )}
    </>
  );
}
