import { DataWrapper } from '@app/components/DataWrapper';
import { GenericTable, TableHeader } from '@app/components/GenericTable';
import { useAuth } from '@app/hooks/useAuth';
import {
  getPointsHistory,
  PointHistoryEntry,
  PointType,
} from '@app/services/pointService';
import { formatDateTime } from '@app/util/helpers';
import { Avatar, Stack, Typography } from '@mui/material';
import { TableRowActions } from './TableRowActions';

function getPointTitle(entry: PointHistoryEntry): string {
  switch (entry.source.type) {
    case 'LECTURE_ATTENDANCE':
      return entry.source.lecture?.name || 'Lecture';
    case 'TUTORING_ATTENDANCE':
      return entry.source.tutoring?.name || 'Tutoring';
    case 'EVENT_ATTENDANCE':
      return entry.source.event?.name || 'Event';
    default:
      return '-';
  }
}

function getPointType(type: PointType): string {
  switch (type) {
    case 'LECTURE_ATTENDANCE':
      return 'Attendance';
    case 'BONUS_LECTURE_ATTENDANCE':
      return 'Attendance Bonus';
    case 'TUTORING_ATTENDANCE':
      return 'Tutoring';
    case 'EVENT_ATTENDANCE':
      return 'Event';
    case 'FINANCIAL_AID':
      return 'Financial Aid';
    case 'STUDENT_PORTAL_LOGIN':
      return 'Daily Login';
    default:
      return 'Unknown';
  }
}

const headers: TableHeader<PointHistoryEntry>[] = [
  {
    headerLabel: 'Student',
    isSortable: true,
    key: 'student.firstName',
    renderCell: row => (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Avatar
          src={row.student.profilePicture}
          alt={row.student.firstName}
          sx={{ height: 32, width: 32 }}
        />
        <Typography>
          {row.student.firstName} {row.student.lastName} <br />
          <small>
            <b>{row.student.customId}</b> - {row.student.email}
          </small>
        </Typography>
      </Stack>
    ),
  },
  {
    headerLabel: 'Title',
    isSortable: false,
    key: 'id',
    renderCell: row => getPointTitle(row),
  },
  {
    headerLabel: 'Claimed On',
    isSortable: true,
    key: 'timestamp',
    renderCell: row => formatDateTime(row.timestamp),
  },
  {
    headerLabel: 'Point Category',
    isSortable: false,
    key: 'source.type',
    renderCell: row => getPointType(row.source.type),
  },
  {
    headerLabel: 'Points',
    isSortable: false,
    key: 'source.score',
  },
  {
    headerLabel: 'Actions',
    isSortable: false,
    key: 'id',
    renderCell: row => <TableRowActions data={row} />,
  },
];

export function Table() {
  const { isAdmin } = useAuth();

  return (
    <DataWrapper<PointHistoryEntry>
      id="points-history"
      title="Points History"
      filterConfig={[
        {
          field: 'title',
          label: 'Title',
          type: 'contains',
          isCaseInsensitive: true,
          value: '',
        },
        {
          field: 'source.type',
          label: 'Point Category',
          value: '',
          type: 'equals',
          options: [
            { label: 'All', value: '' },
            { label: 'Attendance Bonus', value: 'BONUS_LECTURE_ATTENDANCE' },
            { label: 'Lecture', value: 'LECTURE_ATTENDANCE' },
            { label: 'Tutoring', value: 'TUTORING_ATTENDANCE' },
            { label: 'Event', value: 'EVENT_ATTENDANCE' },
            { label: 'Financial Aid', value: 'FINANCIAL_AID' },
            { label: 'Daily Login', value: 'STUDENT_PORTAL_LOGIN' },
          ],
        },
        {
          field: 'student.customId',
          label: 'Student ID',
          type: 'contains',
          isCaseInsensitive: true,
          value: '',
        },
        {
          field: 'student.firstName',
          label: 'First Name',
          type: 'contains',
          isCaseInsensitive: true,
          value: '',
        },
        {
          field: 'student.lastName',
          label: 'Last Name',
          type: 'contains',
          isCaseInsensitive: true,
          value: '',
        },
      ]}
      fetchData={getPointsHistory}
      render={(data, sortBy, setSortBy) => (
        <GenericTable
          tableHeaders={isAdmin ? headers : headers.slice(0, -1)}
          data={data}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      )}
    />
  );
}
