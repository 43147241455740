/* eslint-disable @typescript-eslint/no-floating-promises */
import { Button, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { Btn } from './Btn';
import { GenericModal } from './GenericModal';

interface Props {
  text: string | React.ReactNode;
  open: boolean;
  onClose: () => void;
  deleteFn: () => Promise<void>;
  queryKey?: string;
}

export function ConfirmDeleteModal({
  text,
  open,
  onClose,
  deleteFn,
  queryKey,
}: Props) {
  const queryClient = useQueryClient();
  const mutation = useMutation(deleteFn, {
    onSuccess: () => {
      if (queryKey) queryClient.invalidateQueries(queryKey);
      onClose();
    },
  });

  const onDelete = () => {
    mutation.mutate();
  };

  return (
    <GenericModal
      title="Confirm Delete"
      isOpen={open}
      onClose={onClose}
      useOnlyCloseButton
    >
      <Typography>Are you sure you want to delete this record?</Typography>
      <Typography fontWeight="bold" mb={4}>
        {text}
      </Typography>
      <Stack direction="row" justifyContent="end" width={1} spacing={2}>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Btn
          variant="contained"
          color="error"
          onClick={onDelete}
          isLoading={mutation.isLoading}
        >
          Delete
        </Btn>
      </Stack>
    </GenericModal>
  );
}
