import { useAuth } from '@app/hooks/useAuth';
import { usePointConfig } from '@app/hooks/usePointConfig';
import { formatDate } from '@app/util/helpers';
import { Button, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { CreateSemester } from './CreateSemester';

export function SetSemesterForm() {
  const { isAdmin } = useAuth();

  const [isDirty, setIsDirty] = useState(false);
  const { data, setSemester, semestersError } = usePointConfig({
    onSemesterUpdateSuccess: () => setIsDirty(false),
  });
  const options = data!.semesters;
  const selected = options.find(sem => sem.isActive)?.id || '';

  const [value, setValue] = useState(selected);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setIsDirty(true);
  };

  useEffect(() => {
    if (data?.semesters) {
      setValue(data.semesters.find(sem => sem.isActive)?.id || '');
    }
  }, [data?.semesters]);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    e.currentTarget.checkValidity();
    if (value) {
      setSemester(value);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack gap={1}>
        <Stack direction="row" alignItems="center" width={1}>
          <Typography sx={{ width: 0.5 }}>Set Active Semester</Typography>
          <TextField
            onChange={onChange}
            value={value}
            defaultValue={selected}
            size="small"
            sx={{ width: 0.5 }}
            select
            inputProps={{
              readOnly: !isAdmin,
            }}
          >
            {options.map(semester => (
              <MenuItem key={semester.id} value={semester.id}>
                {formatDate(semester.start)}- {formatDate(semester.end)}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        {semestersError && (
          <Typography color="error">{semestersError}</Typography>
        )}

        {isAdmin && (
          <Stack direction="row" justifyContent="flex-end" gap={2}>
            <CreateSemester />
            <Button
              variant="contained"
              type="submit"
              disabled={!isDirty}
              sx={{ alignSelf: 'end' }}
            >
              Save
            </Button>
          </Stack>
        )}
      </Stack>
    </form>
  );
}
