import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { usePointConfig } from '@app/hooks/usePointConfig';
import { Box, Card, CardContent } from '@mui/material';
import { PointEntriesForm } from './PointEntriesForm';
import { SetSemesterForm } from './SetSemesterForm';

export function PointSystem() {
  const { data } = usePointConfig();

  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Dashboard',
            link: '/',
          },
          {
            label: 'Point System',
            link: '/points/system',
          },
        ]}
      />

      {data && (
        <Box>
          <Card variant="outlined" sx={{ maxWidth: 700, mb: 4 }}>
            <CardContent>
              <SetSemesterForm />
            </CardContent>
          </Card>
          <Card variant="outlined">
            <CardContent>
              <PointEntriesForm />
            </CardContent>
          </Card>
        </Box>
      )}
    </Layout>
  );
}
