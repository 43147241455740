import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { useAuth } from '@app/hooks/useAuth';
import { Actions } from './Actions';
import { Table } from './Tables';

export function Students() {
  const { isAdmin } = useAuth();

  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Dashboard',
            link: '/',
          },
          {
            label: 'Students',
            link: '/users/students',
          },
        ]}
      />
      {isAdmin && <Actions />}
      <Table />
    </Layout>
  );
}
