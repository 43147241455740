/* eslint-disable unicorn/no-useless-undefined */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Typography,
  Button,
  Stack,
  TextField,
  Card,
  CardMedia,
  Grid,
  IconButton,
  Switch,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { useState } from 'react';
import { FileUploadField } from '@app/components/FileUploadField';
import { useMutation, useQueryClient } from 'react-query';
import { useAuth } from '@app/hooks/useAuth';
import {
  addLecturerImage,
  deleteLecturerImage,
  LecturerData,
  upadteLecturerAdditionalDetails,
  upadteLecturerBasicDetails,
} from '@app/services/lecturerService';
import { GenericModal, useModal } from '@app/components/GenericModal';
import { Delete } from '@mui/icons-material';
import { Btn } from '@app/components/Btn';

interface Props {
  data: LecturerData;
  onClose: () => void;
}
export function EditForm({ data, onClose }: Props) {
  return (
    <>
      <Stack
        spacing={2}
        sx={{ overflowY: 'auto', maxHeight: '70vh' }}
        paddingRight={1}
        py={2}
      >
        <BasicDetailsForm data={data} id={data.userId} onClose={onClose} />
        <AdditionalDetailsForm data={data} id={data.id} onClose={onClose} />
        <ImagesForm data={data} id={data.id} />
      </Stack>
      <Stack direction="row" justifyContent="end" width={1}>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Close
        </Button>
      </Stack>
    </>
  );
}

interface BasicDetailsFormProps {
  id: string;
  data: LecturerData;
  onClose: () => void;
}
function BasicDetailsForm({ id, data, onClose }: BasicDetailsFormProps) {
  const [firstName, setFirstName] = useState<string>(data.user.firstName);
  const [lastName, setLastName] = useState<string>(data.user.lastName);
  const [profilePicture, setProfilePicture] = useState<File>();

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';
  const queryClient = useQueryClient();
  const mutation = useMutation(upadteLecturerBasicDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries('professors');
      onClose();
    },
  });

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutation.mutate({
      token,
      id,
      data: {
        firstName,
        lastName,
        profilePicture,
      },
    });
  };

  return (
    <form onSubmit={submitForm}>
      <Stack spacing={2} paddingRight={1} py={2}>
        <Typography fontWeight="bold">Basic Details</Typography>
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="First Name"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
        />
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Last Name"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
        />

        <FileUploadField
          label="Profile Picture"
          onChange={files => setProfilePicture(files[0])}
        />
      </Stack>

      {mutation.error && (
        <Typography color="error">
          {(mutation.error as Error).message}
        </Typography>
      )}

      <Stack direction="row" justifyContent="end" width={1}>
        <Btn
          variant="contained"
          color="primary"
          type="submit"
          isLoading={mutation.isLoading}
        >
          Update
        </Btn>
      </Stack>
    </form>
  );
}

interface AdditionalDetailsFormProps {
  id: string;
  data: LecturerData;
  onClose: () => void;
}
function AdditionalDetailsForm({
  id,
  data,
  onClose,
}: AdditionalDetailsFormProps) {
  const [isAdvisor, setIsAdvisor] = useState<boolean>(data.isAdvisor);
  const [advisorUrl, setAdvisorUrl] = useState<string>(data.advisorUrl || '');
  const [title, setTitle] = useState<string>(data.title);
  const [quote, setQuote] = useState<string>(data.quote);
  const [favouriteBook, setFavouriteBook] = useState<string>(
    data.favouriteBook || '',
  );
  const [favouriteBookImage, setFavouriteBookImage] = useState<File>();
  const [favouriteBookUrl, setFavouriteBookUrl] = useState<string>(
    data.favouriteBookUrl || '',
  );
  const [hobbies, setHobbies] = useState<string>(data.hobbies || '');
  const [favouriteFood, setFavouriteFood] = useState<string>(
    data.favouriteFood || '',
  );
  const [hometown, setHometown] = useState<string>(data.hometown);
  const [hometownDesc, setHometownDesc] = useState<string>(
    data.hometownDesc || '',
  );
  const [funFacts, setFunFacts] = useState<string>(data.funFacts || '');

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';
  const queryClient = useQueryClient();
  const mutation = useMutation(upadteLecturerAdditionalDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries('professors');
      onClose();
    },
  });

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutation.mutate({
      token,
      id,
      data: {
        isAdvisor: isAdvisor ? 'true' : 'false',
        advisorUrl,
        title,
        quote,
        favouriteBook,
        favouriteBookImage,
        favouriteBookUrl,
        favouriteFood,
        funFacts,
        hobbies,
        hometown,
        hometownDesc,
      },
    });
  };

  return (
    <form onSubmit={submitForm}>
      <Stack spacing={2} paddingRight={1} py={2}>
        <Typography fontWeight="bold" pt={2}>
          Additional Details
        </Typography>

        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Title"
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Quote"
          multiline
          rows={3}
          value={quote}
          onChange={e => setQuote(e.target.value)}
        />

        <FormGroup>
          <FormControlLabel
            value={isAdvisor}
            onChange={() => setIsAdvisor(!isAdvisor)}
            control={<Switch defaultChecked={data.isAdvisor} />}
            label="Is a advisor?"
          />
        </FormGroup>
        {isAdvisor && (
          <TextField
            fullWidth
            type="url"
            size="small"
            label="Advisor Profile URL"
            value={advisorUrl}
            onChange={e => setAdvisorUrl(e.target.value)}
          />
        )}

        <TextField
          fullWidth
          type="text"
          size="small"
          label="Favourite Book"
          value={favouriteBook}
          onChange={e => setFavouriteBook(e.target.value)}
        />
        <FileUploadField
          label="Favourite Book Image"
          onChange={files => setFavouriteBookImage(files[0])}
        />

        <TextField
          fullWidth
          type="url"
          size="small"
          label="Favourite Book URL"
          value={favouriteBookUrl}
          onChange={e => setFavouriteBookUrl(e.target.value)}
        />
        <TextField
          fullWidth
          type="text"
          size="small"
          label="Hobbies"
          multiline
          rows={3}
          value={hobbies}
          onChange={e => setHobbies(e.target.value)}
        />
        <TextField
          fullWidth
          type="text"
          size="small"
          label="Favourite Foods"
          multiline
          rows={3}
          value={favouriteFood}
          onChange={e => setFavouriteFood(e.target.value)}
        />
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Home Town"
          value={hometown}
          onChange={e => setHometown(e.target.value)}
        />
        <TextField
          fullWidth
          type="text"
          size="small"
          label="Home Town Description"
          multiline
          rows={3}
          value={hometownDesc}
          onChange={e => setHometownDesc(e.target.value)}
        />
        <TextField
          fullWidth
          type="text"
          size="small"
          label="Fun Facts"
          multiline
          rows={3}
          value={funFacts}
          onChange={e => setFunFacts(e.target.value)}
        />
      </Stack>

      {mutation.error && (
        <Typography color="error">
          {(mutation.error as Error).message}
        </Typography>
      )}

      <Stack direction="row" justifyContent="end" width={1}>
        <Btn
          variant="contained"
          color="primary"
          type="submit"
          isLoading={mutation.isLoading}
        >
          Update
        </Btn>
      </Stack>
    </form>
  );
}

interface ImagesFormProps {
  id: string;
  data: LecturerData;
}
function ImagesForm({ id, data }: ImagesFormProps) {
  const [caption, setCaption] = useState<string>('');
  const [image, setImage] = useState<File>();

  const { isOpen, closeModal, openModal } = useModal();

  const onCloseModal = () => {
    closeModal();
    setCaption('');
    setImage(undefined);
  };

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';
  const queryClient = useQueryClient();
  const mutation = useMutation(addLecturerImage, {
    onSuccess: () => {
      queryClient.invalidateQueries('professors');
      closeModal();
      setCaption('');
      setImage(undefined);
    },
  });

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (image) {
      mutation.mutate({
        token,
        id,
        data: {
          caption,
          image,
        },
      });
    }
  };

  return (
    <Stack spacing={2} paddingRight={1} py={2}>
      <Typography fontWeight="bold" pt={2}>
        Gallery
      </Typography>
      <Grid container>
        {data.images.map(img => (
          <Grid key={img.id} item xs={6} p={1}>
            <ImageCard id={img.id} url={img.url} caption={img.caption} />
          </Grid>
        ))}
        <Grid item xs={6} p={1}>
          <Button
            variant="outlined"
            color="primary"
            onClick={openModal}
            fullWidth
            sx={{ borderStyle: 'dashed', height: '100%', minHeight: 100 }}
          >
            Add Image
          </Button>
        </Grid>
      </Grid>

      <GenericModal isOpen={isOpen} onClose={onCloseModal} title="Add Image">
        <form onSubmit={submitForm}>
          <Stack spacing={2} paddingRight={1} py={2}>
            <FileUploadField
              label="Image"
              onChange={files => setImage(files[0])}
              required
            />
            <TextField
              fullWidth
              required
              type="text"
              size="small"
              label="Caption"
              value={caption}
              onChange={e => setCaption(e.target.value)}
            />

            {mutation.error && (
              <Typography color="error">
                {(mutation.error as Error).message}
              </Typography>
            )}

            <Stack direction="row" justifyContent="end" width={1}>
              <Btn
                variant="contained"
                color="primary"
                type="submit"
                isLoading={mutation.isLoading}
              >
                Add Image
              </Btn>
            </Stack>
          </Stack>
        </form>
      </GenericModal>
    </Stack>
  );
}

interface ImagesCardProps {
  id: string;
  caption: string;
  url: string;
}
function ImageCard({ id, caption, url }: ImagesCardProps) {
  const { tokenData } = useAuth();
  const token = tokenData?.token || '';
  const queryClient = useQueryClient();
  const mutation = useMutation(deleteLecturerImage, {
    onSuccess: () => {
      queryClient.invalidateQueries('professors');
    },
  });

  const onDelete = () => {
    mutation.mutate({
      token,
      id,
    });
  };

  return (
    <Card variant="outlined" sx={{ position: 'relative' }}>
      <IconButton
        onClick={onDelete}
        sx={{
          position: 'absolute',
          top: 5,
          right: 5,
          zIndex: 1,
          bgcolor: '#fff',
          '&:hover': {
            bgcolor: '#ffffff99',
          },
        }}
      >
        <Delete color="error" />
      </IconButton>
      <CardMedia
        component="img"
        src={url}
        alt={caption}
        sx={{ height: 100, width: 1, objectFit: 'contain' }}
      />
      <Typography variant="body2" align="center">
        {caption}
      </Typography>
    </Card>
  );
}
