import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useProfile } from '@app/hooks/useProfile';

export function UserInfo() {
  const { data } = useProfile();
  const navigate = useNavigate();
  const gotoProfile = () => navigate('/profile');

  return (
    <ListItemButton
      onClick={gotoProfile}
      sx={{ color: 'white', flexGrow: 0, pl: 4 }}
    >
      <ListItemAvatar>
        <Avatar src={data?.profilePicture} alt={data?.firstName} />
      </ListItemAvatar>
      <ListItemText
        primary={data ? `${data.firstName} ${data.lastName}` : 'Loading...'}
        secondary={data?.email}
        secondaryTypographyProps={{ color: 'white' }}
      />
    </ListItemButton>
  );
}
