import { BasicLayout } from '@app/components/Layout';
import { resetPassword } from '@app/services/authService';
import { CheckCircleOutlined, Key } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useMutation } from 'react-query';
import loginImg from '@assets/login-img.png';
import { useNavigate, useParams } from 'react-router-dom';
import { Btn } from '@app/components/Btn';

export function ResetPassword() {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();

  const gotoLogin = () => navigate('/login');

  const [error, setError] = useState<string>('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const onNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };
  const onConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const mutation = useMutation(resetPassword);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    if (newPassword && confirmPassword && token) {
      mutation.mutate({ newPassword, token });
    }
  };

  return (
    <BasicLayout>
      <Stack alignItems="center">
        <Container maxWidth="xs">
          <Box
            component="img"
            src={loginImg}
            alt="SBT"
            sx={{ maxWidth: 1, objectFit: 'contain' }}
          />

          <Typography variant="h4" fontWeight="bold" align="center">
            Reset Password
          </Typography>
          <Typography align="center" mb={2}>
            Enter your new password.
          </Typography>

          <Card variant="outlined" sx={{ p: 2 }}>
            {mutation.isSuccess ? (
              <Stack alignItems="center">
                <CheckCircleOutlined color="success" fontSize="large" />
                <Typography color="green" align="center" mb={2}>
                  Your password has been reset.
                </Typography>
                <Button variant="contained" color="primary" onClick={gotoLogin}>
                  Go To Login
                </Button>
              </Stack>
            ) : (
              <form onSubmit={onSubmit}>
                <Stack component={CardContent} alignItems="center" spacing={2}>
                  <TextField
                    placeholder="New Password"
                    type="password"
                    value={newPassword}
                    onChange={onNewPasswordChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Key />
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                    fullWidth
                    required
                  />
                  <TextField
                    placeholder="Confirm Password"
                    type="password"
                    value={confirmPassword}
                    onChange={onConfirmPasswordChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Key />
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                    fullWidth
                    required
                  />

                  {(error || mutation.isError) && (
                    <Typography color="error" align="left" width={1}>
                      *{error || (mutation.error as Error).message}
                    </Typography>
                  )}

                  <Btn
                    variant="contained"
                    type="submit"
                    isLoading={mutation.isLoading}
                  >
                    Submit
                  </Btn>
                </Stack>
              </form>
            )}
          </Card>
        </Container>
      </Stack>
    </BasicLayout>
  );
}
