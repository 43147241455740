import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@app/hooks/useAuth';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Email, Key } from '@mui/icons-material';

import loginImg from '@assets/login-img.png';

import { BasicLayout } from '@app/components/Layout';

function Login() {
  const goto = useNavigate();
  const { login } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const forgotPassword = () => {
    goto('/forgot-password');
  };

  const handleLogin = async () => {
    setError('');
    const err = await login(email, password);
    if (err) {
      setError(err);
      return;
    }
    goto('/');
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (e.currentTarget.checkValidity()) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleLogin();
    }
  };

  return (
    <BasicLayout>
      <Stack alignItems="center" mb={4}>
        <Container maxWidth="xs">
          <Box
            component="img"
            src={loginImg}
            alt="SBT"
            sx={{ width: 1, objectFit: 'contain', maxHeight: '20vh' }}
          />

          <Typography variant="h4" fontWeight="bold" align="center">
            Welcome Back
          </Typography>
          <Typography align="center" mb={2}>
            Please sign in to your account
          </Typography>

          <Card variant="outlined">
            <form onSubmit={handleSubmit}>
              <Stack
                component={CardContent}
                alignItems="center"
                spacing={2}
                sx={{ p: 4 }}
              >
                <TextField
                  placeholder="Email"
                  type="email"
                  value={email}
                  onChange={onChangeEmail}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  fullWidth
                  required
                />
                <TextField
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={onChangePassword}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Key />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  fullWidth
                  required
                />
                {error && (
                  <Typography color="error" align="left" width={1}>
                    *{error}
                  </Typography>
                )}
                <Button variant="contained" type="submit">
                  Login
                </Button>
                <Button
                  variant="text"
                  onClick={forgotPassword}
                  sx={{ textTransform: 'none' }}
                >
                  Forgot Password?
                </Button>
              </Stack>
            </form>
          </Card>
        </Container>
      </Stack>
    </BasicLayout>
  );
}

export { Login };
