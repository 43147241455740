import { DataQueryOptions, Results } from '@app/components/DataWrapper';
import { apiCall, apiCallVoid, getFormData } from '@app/util/helpers';
import {
  LECTURERS_URL,
  LECTURER_IMAGE_DELETE_URL,
  LECTURER_CREATE_URL,
  LECTURER_DELETE_URL,
  LECTURER_UPDATE_ADDITIONAL_URL,
  LECTURER_UPDATE_BASIC_URL,
  LECTURER_IMAGE_ADD_URL,
} from 'config';

interface LecturerImage {
  id: string;
  url: string;
  caption: string;
}

export interface LecturerData {
  id: string;
  userId: string;
  isAdvisor: boolean;
  advisorUrl?: string;
  title: string;
  quote: string;
  favouriteBook?: string;
  favouriteBookImage?: string;
  favouriteBookUrl?: string;
  hobbies?: string;
  favouriteFood?: string;
  hometown: string;
  hometownDesc?: string;
  funFacts?: string;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    profilePicture?: string;
  };
  images: LecturerImage[];
}

export async function getLecturers(
  token: string,
  options: DataQueryOptions,
): Promise<Results<LecturerData>> {
  return apiCall({
    url: LECTURERS_URL,
    token,
    json: options,
  });
}

export interface CreateLecturerData {
  firstName: string;
  lastName: string;
  email: string;
  isAdvisor: string;
  advisorUrl: string;
  title: string;
  quote: string;
  favouriteBook: string;
  favouriteBookUrl: string;
  hobbies: string;
  favouriteFood: string;
  hometown: string;
  hometownDesc: string;
  funFacts: string;
  profilePicture: File;
  favouriteBookImage: File | undefined;
}
interface CreateLecturerOptions {
  token: string;
  data: CreateLecturerData;
}
export async function createLecturer({
  token,
  data,
}: CreateLecturerOptions): Promise<void> {
  await apiCallVoid({
    url: LECTURER_CREATE_URL,
    token,
    formData: getFormData(data),
    successMessage: 'Professor created successfully',
  });
}

export interface UpdateBasicLecturerData {
  firstName: string | undefined;
  lastName: string | undefined;
  profilePicture: File | undefined;
}
interface UpdateBasicLecturerOptions {
  token: string;
  id: string;
  data: UpdateBasicLecturerData;
}
export async function upadteLecturerBasicDetails({
  token,
  id,
  data,
}: UpdateBasicLecturerOptions): Promise<void> {
  await apiCallVoid({
    url: `${LECTURER_UPDATE_BASIC_URL}${id}`,
    token,
    formData: getFormData(data),
    successMessage: 'Updated successfully',
  });
}

export interface UpdateAdditionalLecturerData {
  isAdvisor: string | undefined;
  advisorUrl: string | undefined;
  title: string | undefined;
  quote: string | undefined;
  favouriteBook: string | undefined;
  favouriteBookUrl: string | undefined;
  hobbies: string | undefined;
  favouriteFood: string | undefined;
  hometown: string | undefined;
  hometownDesc: string | undefined;
  funFacts: string | undefined;
  favouriteBookImage: File | undefined;
}
interface UpdateAdditionalLecturerOptions {
  token: string;
  id: string;
  data: UpdateAdditionalLecturerData;
}
export async function upadteLecturerAdditionalDetails({
  token,
  id,
  data,
}: UpdateAdditionalLecturerOptions): Promise<void> {
  await apiCallVoid({
    url: `${LECTURER_UPDATE_ADDITIONAL_URL}${id}`,
    token,
    formData: getFormData(data),
    successMessage: 'Updated successfully',
  });
}

interface DeleteLecturerOptions {
  token: string;
  id: string;
}
export async function deleteLecturer({
  token,
  id,
}: DeleteLecturerOptions): Promise<void> {
  await apiCallVoid({
    method: 'DELETE',
    url: `${LECTURER_DELETE_URL}${id}`,
    token,
    successMessage: 'Professor deleted successfully',
  });
}

export interface AddLecturerImageData {
  caption: string;
  image: File;
}
interface AddLecturerImageOptions {
  token: string;
  id: string;
  data: AddLecturerImageData;
}
export async function addLecturerImage({
  token,
  id,
  data,
}: AddLecturerImageOptions): Promise<void> {
  await apiCallVoid({
    url: `${LECTURER_IMAGE_ADD_URL}${id}`,
    token,
    formData: getFormData(data),
    successMessage: 'Image added successfully',
  });
}

interface DeleteLecturerImageOptions {
  token: string;
  id: string;
}
export async function deleteLecturerImage({
  token,
  id,
}: DeleteLecturerImageOptions): Promise<void> {
  await apiCallVoid({
    method: 'DELETE',
    url: `${LECTURER_IMAGE_DELETE_URL}${id}`,
    token,
    successMessage: 'Image deleted successfully',
  });
}
