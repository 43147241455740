/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Typography,
  Button,
  Stack,
  TextField,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { useState } from 'react';
import { FileUploadField } from '@app/components/FileUploadField';
import { useMutation, useQueryClient } from 'react-query';
import { useAuth } from '@app/hooks/useAuth';
import { createLecturer } from '@app/services/lecturerService';
import { Btn } from '@app/components/Btn';

interface Props {
  onClose: () => void;
}

export function AddForm(props: Props) {
  const { onClose } = props;
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [isAdvisor, setIsAdvisor] = useState<boolean>(false);
  const [advisorUrl, setAdvisorUrl] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [profilePicture, setProfilePicture] = useState<File>();
  const [quote, setQuote] = useState<string>('');
  const [favouriteBook, setFavouriteBook] = useState<string>('');
  const [favouriteBookImage, setFavouriteBookImage] = useState<File>();
  const [favouriteBookUrl, setFavouriteBookUrl] = useState<string>('');
  const [hobbies, setHobbies] = useState<string>('');
  const [favouriteFood, setFavouriteFood] = useState<string>('');
  const [hometown, setHometown] = useState<string>('');
  const [hometownDesc, setHometownDesc] = useState<string>('');
  const [funFacts, setFunFacts] = useState<string>('');

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';
  const queryClient = useQueryClient();
  const mutation = useMutation(createLecturer, {
    onSuccess: () => {
      queryClient.invalidateQueries('professors');
      onClose();
    },
  });

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (profilePicture) {
      mutation.mutate({
        token,
        data: {
          firstName,
          lastName,
          isAdvisor: isAdvisor ? 'true' : 'false',
          advisorUrl,
          title,
          email,
          quote,
          favouriteBook,
          favouriteBookImage,
          favouriteBookUrl,
          favouriteFood,
          funFacts,
          hobbies,
          hometown,
          hometownDesc,
          profilePicture,
        },
      });
    }
  };

  return (
    <form onSubmit={submitForm}>
      <Stack
        spacing={2}
        sx={{ overflowY: 'auto', maxHeight: '70vh' }}
        paddingRight={1}
        py={2}
      >
        <Typography fontWeight="bold">Basic Details</Typography>
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="First Name"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
        />
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Last Name"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
        />
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <FileUploadField
          label="Profile Picture"
          onChange={files => setProfilePicture(files[0])}
          required
        />

        <Typography fontWeight="bold" pt={2}>
          Additional Details
        </Typography>
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Title"
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Quote"
          multiline
          rows={3}
          value={quote}
          onChange={e => setQuote(e.target.value)}
        />

        <FormGroup>
          <FormControlLabel
            value={isAdvisor}
            onChange={() => setIsAdvisor(!isAdvisor)}
            control={<Switch />}
            label="Is a advisor?"
          />
        </FormGroup>
        {isAdvisor && (
          <TextField
            fullWidth
            type="url"
            size="small"
            label="Advisor Profile URL"
            value={advisorUrl}
            onChange={e => setAdvisorUrl(e.target.value)}
          />
        )}

        <TextField
          fullWidth
          type="text"
          size="small"
          label="Favourite Book"
          value={favouriteBook}
          onChange={e => setFavouriteBook(e.target.value)}
        />
        <FileUploadField
          label="Favourite Book Image"
          onChange={files => setFavouriteBookImage(files[0])}
        />
        <TextField
          fullWidth
          type="url"
          size="small"
          label="Favourite Book URL"
          value={favouriteBookUrl}
          onChange={e => setFavouriteBookUrl(e.target.value)}
        />

        <TextField
          fullWidth
          type="text"
          size="small"
          label="Hobbies"
          multiline
          rows={3}
          value={hobbies}
          onChange={e => setHobbies(e.target.value)}
        />
        <TextField
          fullWidth
          type="text"
          size="small"
          label="Favourite Foods"
          multiline
          rows={3}
          value={favouriteFood}
          onChange={e => setFavouriteFood(e.target.value)}
        />
        <TextField
          fullWidth
          required
          type="text"
          size="small"
          label="Home Town"
          value={hometown}
          onChange={e => setHometown(e.target.value)}
        />
        <TextField
          fullWidth
          type="text"
          size="small"
          label="Home Town Description"
          multiline
          rows={3}
          value={hometownDesc}
          onChange={e => setHometownDesc(e.target.value)}
        />
        <TextField
          fullWidth
          type="text"
          size="small"
          label="Fun Facts"
          multiline
          rows={3}
          value={funFacts}
          onChange={e => setFunFacts(e.target.value)}
        />
      </Stack>

      {mutation.error && (
        <Typography color="error">
          {(mutation.error as Error).message}
        </Typography>
      )}

      <Stack direction="row" justifyContent="end" width={1} pt={2}>
        <Button
          variant="outlined"
          color="primary"
          sx={{ mr: 1 }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Btn
          variant="contained"
          color="primary"
          type="submit"
          isLoading={mutation.isLoading}
        >
          Add New
        </Btn>
      </Stack>
    </form>
  );
}
