import { DataQueryOptions, Results } from '@app/components/DataWrapper';
import { apiCall, apiCallVoid, getFormData } from '@app/util/helpers';
import {
  ALL_BUSINESS_LIBRARY_URL,
  ALL_FINACIAL_LITERACY_URL,
  BUSINESS_LIBRARY_URL,
  FINACIAL_LITERACY_URL,
} from 'config';

export interface ContentData {
  id: string;
  url: string;
  image?: string;
  title: string;
  description: string;
  youtubeUrl?: string;
  btnText?: string;
}

interface GetOptoins {
  token: string;
  options: DataQueryOptions;
}

interface CreateOptions {
  token: string;
  url: string;
  image: File | undefined;
  title: string;
  description: string;
  youtubeUrl: string | undefined;
  btnText: string | undefined;
}

interface UpdateOptions {
  token: string;
  id: string;
  url: string | undefined;
  image: File | undefined;
  title: string | undefined;
  description: string | undefined;
  youtubeUrl: string | undefined;
  btnText: string | undefined;
}

interface DeleteOptions {
  token: string;
  id: string;
}

export async function getFinacilaLiteracy({
  token,
  options,
}: GetOptoins): Promise<Results<ContentData>> {
  return apiCall({
    url: ALL_FINACIAL_LITERACY_URL,
    method: 'POST',
    token,
    json: options,
  });
}

export async function createFinacilaLiteracy({
  token,
  ...data
}: CreateOptions): Promise<ContentData> {
  return apiCall({
    url: FINACIAL_LITERACY_URL,
    method: 'POST',
    token,
    formData: getFormData(data),
    successMessage: 'Content created successfully',
  });
}

export async function updateFinacilaLiteracy({
  token,
  id,
  ...data
}: UpdateOptions): Promise<ContentData> {
  return apiCall({
    url: `${FINACIAL_LITERACY_URL}${id}`,
    method: 'PATCH',
    token,
    formData: getFormData(data),
    successMessage: 'Content updated successfully',
  });
}

export async function deleteFinacilaLiteracy({
  token,
  id,
}: DeleteOptions): Promise<void> {
  return apiCallVoid({
    url: `${FINACIAL_LITERACY_URL}${id}`,
    method: 'DELETE',
    token,
    successMessage: 'Content deleted successfully',
  });
}

export async function getBusinessLibrary({
  token,
  options,
}: GetOptoins): Promise<Results<ContentData>> {
  return apiCall({
    url: ALL_BUSINESS_LIBRARY_URL,
    method: 'POST',
    token,
    json: options,
  });
}

export async function createBusinessLibrary({
  token,
  ...data
}: CreateOptions): Promise<ContentData> {
  return apiCall({
    url: BUSINESS_LIBRARY_URL,
    method: 'POST',
    token,
    formData: getFormData(data),
    successMessage: 'Content created successfully',
  });
}

export async function updateBusinessLibrary({
  token,
  id,
  ...data
}: UpdateOptions): Promise<ContentData> {
  return apiCall({
    url: `${BUSINESS_LIBRARY_URL}${id}`,
    method: 'PATCH',
    token,
    formData: getFormData(data),
    successMessage: 'Content updated successfully',
  });
}

export async function deleteBusinessLibrary({
  token,
  id,
}: DeleteOptions): Promise<void> {
  return apiCallVoid({
    url: `${BUSINESS_LIBRARY_URL}${id}`,
    method: 'DELETE',
    token,
    successMessage: 'Content deleted successfully',
  });
}
