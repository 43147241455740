import { DataWrapper } from '@app/components/DataWrapper';
import { useAuth } from '@app/hooks/useAuth';
import {
  ContentData,
  getBusinessLibrary,
} from '@app/services/otherContentService';
import { Card, Grid } from '@mui/material';
import { BusinessLibraryCard } from './BusinessLibraryCard';
import { ListItemActions } from './ListItemActions';

export function ListView() {
  const { isAdmin } = useAuth();

  return (
    <DataWrapper<ContentData>
      id="business-library"
      title="Business Library"
      filterConfig={[]}
      fetchData={getBusinessLibrary}
      render={data => (
        <Grid container spacing={4} px={{ xs: 2, lg: 4 }}>
          {data.map(row => (
            <Grid key={row.id} item xs={12} md={12} lg={6} xl={4}>
              <Card
                variant="outlined"
                sx={{ height: 1, display: 'flex', flexDirection: 'column' }}
              >
                <BusinessLibraryCard
                  title={row.title}
                  url={row.url}
                  btnText={row.btnText}
                />
                {isAdmin && <ListItemActions data={row} />}
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    />
  );
}
