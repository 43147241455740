import { GenericModal, useModal } from '@app/components/GenericModal';
import { useAuth } from '@app/hooks/useAuth';
import { Add } from '@mui/icons-material';
import { Button, Stack, Box } from '@mui/material';
import { Form } from './Form';

export function Actions() {
  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  const {
    isOpen: isAddFormOpen,
    openModal: openAddForm,
    closeModal: closeAddForm,
  } = useModal();

  return (
    <Box>
      <Stack direction="row" justifyContent="end" gap={2} mb={4}>
        <Button variant="contained" startIcon={<Add />} onClick={openAddForm}>
          Event
        </Button>
      </Stack>

      <GenericModal
        title="Add Event"
        isOpen={isAddFormOpen}
        onClose={closeAddForm}
        useOnlyCloseButton
      >
        <Form token={token} onClose={closeAddForm} />
      </GenericModal>
    </Box>
  );
}
