import { GenericModal } from '@app/components/GenericModal';
import { QrGenerator } from '@app/components/QrGenerator';
import { LectureData } from '@app/services/eventService';
import { formatDate, formatTime } from '@app/util/helpers';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';

interface Props {
  open: boolean;
  onClose: () => void;
  data: LectureData;
}
export function DetailModal({ open, onClose, data }: Props) {
  const keyValueRenderer = (key: string, value: string | JSX.Element) => (
    <>
      <Grid item xs={12} sm={3}>
        <Typography variant="body1" fontWeight="bold">
          {key} :
        </Typography>
      </Grid>
      <Grid item xs={12} sm={9} mb={2}>
        <Typography>{value}</Typography>
      </Grid>
    </>
  );

  return (
    <GenericModal title={data.name} isOpen={open} onClose={onClose} width={450}>
      <Grid container>
        {keyValueRenderer('Title', data.name)}
        {keyValueRenderer('Description', data.description)}
        {keyValueRenderer('Date', formatDate(data.startDate))}
        {keyValueRenderer(
          'Time',
          `${formatTime(data.startDate)} - ${formatTime(data.endDate)}`,
        )}
        <Box sx={{ mx: 'auto', mt: 4 }}>
          <QrGenerator id={data.id} value={data.pointSource.qrSecret} />,
        </Box>
      </Grid>
      <Stack direction="row" justifyContent="end" width={1}>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Close
        </Button>
      </Stack>
    </GenericModal>
  );
}
