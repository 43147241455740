import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useAuth } from '@app/hooks/useAuth';

import logoImg from '@assets/login-img.png';

function Home() {
  const { isAdmin } = useAuth();
  return (
    <Layout>
      <BreadCrumbs links={[{ label: 'Dashboard', link: '/' }]} />

      <Stack justifyContent="center" alignItems="center" spacing={2}>
        <Box
          component="img"
          sx={{ height: 150, width: 1, objectFit: 'contain', my: 2 }}
          src={logoImg}
          alt="Student Portal"
        />
        <Typography
          color="primary"
          align="center"
          variant="h2"
          fontWeight="bold"
        >
          Welcome To
          <br />
          {isAdmin ? 'Admin' : 'Lecturer'} Dashboard
        </Typography>
      </Stack>
    </Layout>
  );
}

export { Home };
