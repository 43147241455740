/* eslint-disable @typescript-eslint/no-floating-promises */
import { Btn } from '@app/components/Btn';
import { editStudent, StudentData } from '@app/services/studentsService';
import { Typography, Grid, TextField, Stack, Button } from '@mui/material';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

interface Props {
  onClose: () => void;
  userId: string;
  token: string;
  defaults: StudentData;
}

export function EditForm(props: Props) {
  const { onClose, defaults, userId, token } = props;

  const [error, setError] = useState<string>();
  const [displayName, setDisplayName] = useState<string>(defaults.displayName);
  const [firstName, setFirstName] = useState<string>(defaults.firstName);
  const [lastName, setLastName] = useState<string>(defaults.lastName);
  const [studentId, setStudentId] = useState<string>(defaults.customId);
  const [phone, setPhone] = useState<string | undefined>(defaults.phone);
  const [degreeAudit, setDegreeAudit] = useState<string | undefined>(
    defaults.degreeAudit,
  );

  const queryClient = useQueryClient();
  const mutation = useMutation(editStudent, {
    onSuccess: () => {
      queryClient.invalidateQueries('students');
      onClose();
    },
    onError: (err: Error) => {
      setError(err.message);
    },
  });

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (firstName && lastName && displayName && studentId) {
      mutation.mutate({
        token,
        id: userId,
        firstName,
        lastName,
        displayName,
        phone,
        degreeAudit,
        customId: studentId,
      });
    }
  };

  return (
    <form onSubmit={submitForm}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="number"
            size="small"
            label="Student ID"
            value={studentId}
            onChange={e => setStudentId(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            size="small"
            label="First Name"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            size="small"
            label="Last Name"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            size="small"
            label="Display Name"
            value={displayName}
            onChange={e => setDisplayName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="tel"
            size="small"
            label="Phone Number"
            value={phone}
            onChange={e => setPhone(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="text"
            size="small"
            label="Degree Audit"
            value={degreeAudit}
            onChange={e => setDegreeAudit(e.target.value)}
          />
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography color="error">{error}</Typography>
          </Grid>
        )}
      </Grid>

      <Stack direction="row" justifyContent="end" width={1} margin={1}>
        <Button
          variant="outlined"
          color="primary"
          sx={{ mr: 1 }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Btn
          variant="contained"
          color="primary"
          type="submit"
          isLoading={mutation.isLoading}
        >
          Save
        </Btn>
      </Stack>
    </form>
  );
}
