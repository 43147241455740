import { GenericModal, useModal } from '@app/components/GenericModal';
import { useAuth } from '@app/hooks/useAuth';
import { deleteLecturer, LecturerData } from '@app/services/lecturerService';
import { Delete, Edit, Key, MoreVert } from '@mui/icons-material';
import {
  ButtonBase,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useState } from 'react';
import { ResetPasswordFormModal } from '@app/components/ResetPasswordFormModal';
import { ConfirmDeleteModal } from '@app/components/ConfirmDeleteModal';
import { EditForm } from './EditForm';

interface Props {
  data: LecturerData;
}

export function TableRowActions({ data }: Props) {
  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  const {
    isOpen: editIsOpen,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModal();

  const {
    isOpen: resetPasswordIsOpen,
    openModal: openResetPasswordModal,
    closeModal: closeResetPasswordModal,
  } = useModal();

  const {
    isOpen: deleteIsOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // eslint-disable-next-line unicorn/no-useless-undefined
    setAnchorEl(undefined);
  };

  return (
    <>
      <ButtonBase onClick={handleClick}>
        <MoreVert />
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lecturer-options-menu',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'center',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <MenuItem onClick={openEditModal}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem onClick={openResetPasswordModal}>
          <ListItemIcon>
            <Key />
          </ListItemIcon>
          <ListItemText primary="Change Password" />
        </MenuItem>
        <MenuItem onClick={openDeleteModal} sx={{ color: 'red' }}>
          <ListItemIcon>
            <Delete sx={{ color: 'red' }} />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>

      <GenericModal
        isOpen={editIsOpen}
        onClose={closeEditModal}
        title="Edit Professor"
        useOnlyCloseButton
      >
        <EditForm data={data} onClose={closeEditModal} />
      </GenericModal>

      <ResetPasswordFormModal
        token={token}
        userId={data.userId}
        name={`${data.user.firstName} ${data.user.lastName}`}
        open={resetPasswordIsOpen}
        onClose={closeResetPasswordModal}
      />
      <ConfirmDeleteModal
        text={`${data.user.firstName} ${data.user.lastName}`}
        open={deleteIsOpen}
        onClose={closeDeleteModal}
        deleteFn={() => deleteLecturer({ token, id: data.id })}
        queryKey="professors"
      />
    </>
  );
}
