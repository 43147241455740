import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { Hidden } from '@mui/material';

import logoImg from '@assets/logo.png';

import { UserInfo } from './UserInfo';
import { NavLinks } from './NavLinks';
import { Footer } from './Footer';
import { UserLinks } from './UserLinks';
import { CheckExpiredToken } from '../CheckExpiredToken';

const drawerWidth = 350;

interface Props {
  children?: React.ReactNode;
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export function Layout(props: Props) {
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box
      sx={theme => ({
        bgcolor: theme.palette.primary.main,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Box
          component="img"
          sx={{ height: 50, width: 1, objectFit: 'contain', my: 2 }}
          src={logoImg}
          alt="Student Portal"
        />
        <Divider sx={{ borderColor: '#FFFFFF80' }} />

        <NavLinks />
      </Box>
      <Divider sx={{ borderColor: '#FFFFFF80' }} />
      <UserInfo />
      <UserLinks />
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <CheckExpiredToken>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Hidden smUp>
          <AppBar position="fixed">
            <Toolbar>
              <Box
                component="img"
                sx={{ height: 40, objectFit: 'contain' }}
                src={logoImg}
                alt="Student Portal"
              />
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ ml: 'auto', display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </Hidden>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            minHeight: '100vh',
          }}
        >
          <Box sx={{ flexGrow: 1, px: 4, pb: 4, pt: 6 }}>{children}</Box>

          <Footer />
        </Box>
      </Box>
    </CheckExpiredToken>
  );
}
