import { AppBar, Box, CssBaseline, Toolbar, Typography } from '@mui/material';

import logoImg from '@assets/logo.png';
import { Footer } from './Footer';

interface Props {
  children?: React.ReactNode;
}
export function BasicLayout({ children }: Props) {
  return (
    <Box>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <Box
            component="img"
            sx={{ height: 40, objectFit: 'contain' }}
            src={logoImg}
            alt="Student Portal"
          />
          <Typography variant="h6" noWrap component="div">
            Admin Dashboard
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          bgcolor: '#7E000044',
        }}
      >
        <Toolbar />

        <Box sx={{ flexGrow: 1 }}>{children}</Box>

        <Footer />
      </Box>
    </Box>
  );
}
