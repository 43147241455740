import { useAuth } from '@app/hooks/useAuth';
import { Logout } from '@mui/icons-material';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function UserLinks() {
  const navigate = useNavigate();

  const { logout } = useAuth();

  const logoutClick = () => {
    logout();
    navigate('/login');
  };

  return (
    <List disablePadding>
      <ListItemButton onClick={logoutClick} sx={{ px: 4, color: 'white' }}>
        <ListItemIcon>
          <Logout sx={{ color: 'white' }} />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
}
