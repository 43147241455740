/* eslint-disable unicorn/no-useless-undefined */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { Btn } from '@app/components/Btn';
import { useAuth } from '@app/hooks/useAuth';
import { addDegreeAuditsFromCSV } from '@app/services/studentsService';
import { Typography, Stack, Button, Grid, Divider } from '@mui/material';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

interface Props {
  onClose: () => void;
}

//  Function to truncate displayed name string of selected file
function truncateFileName(fileName: string) {
  const fileType = fileName.split('.')[fileName.split('.').length - 1];
  const fileNameWithoutType = fileName.split('.')[0];
  const maxNumberOfChars = 20;
  if (fileNameWithoutType.length > maxNumberOfChars) {
    return `${fileNameWithoutType.slice(0, maxNumberOfChars)}...${fileType}`;
  }
  return fileName;
}

export function AddDegreeAuditByCsvForm(props: Props) {
  const { onClose } = props;

  const [error, setError] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<File>();

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';
  const queryClient = useQueryClient();
  const mutation = useMutation(addDegreeAuditsFromCSV, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('students');
      onClose();
    },
    onError: (err: Error) => {
      setError(err.message);
    },
  });

  //  Handler function for handling file input
  const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setSelectedFile(fileList[0]);
  };

  const submitFile = () => {
    setError('');
    if (selectedFile) {
      mutation.mutate({
        token,
        file: selectedFile,
      });
    } else {
      setError('Please select a file');
    }
  };

  const onModalClose = () => {
    setSelectedFile(undefined);
    onClose();
  };

  return (
    <Form
      onSubmit={submitFile}
      onClose={onModalClose}
      onChange={handleFileSelection}
      selectedFile={selectedFile?.name}
      error={error}
      isLoading={mutation.isLoading}
    />
  );
}

interface FormProps {
  onClose: () => void;
  onSubmit: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedFile?: string;
  error?: string;
  isLoading?: boolean;
}
function Form({
  onClose,
  onSubmit,
  onChange,
  selectedFile,
  error,
  isLoading,
}: FormProps) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bold" color="gray">
            Upload a CSV file of students&apos; degree audits
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="red">
            Important! Make sure column titles are
            <br /> <b>Id</b>, <b>Degree Audit</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="start"
            spacing={2}
            alignContent="center"
          >
            <Button
              variant="outlined"
              color="primary"
              sx={{ fontWeight: 'bold' }}
              component="label"
            >
              Choose File
              <input
                accept=".csv"
                style={{ display: 'none' }}
                id="csv-file"
                type="file"
                multiple={false}
                onChange={onChange}
              />
            </Button>
            <Typography variant="body1" color="gray">
              {selectedFile
                ? truncateFileName(selectedFile)
                : 'No file uploaded yet'}
            </Typography>
          </Stack>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Typography color="error">*{error}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Stack direction="row" justifyContent="end" spacing={2} mt={2}>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Close
        </Button>
        <Btn
          variant="contained"
          color="primary"
          type="submit"
          onClick={onSubmit}
          isLoading={isLoading}
        >
          Update
        </Btn>
      </Stack>
    </>
  );
}
