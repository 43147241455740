import {
  BusinessCenter,
  Event,
  ExpandLess,
  ExpandMore,
  Feed,
  History,
  HistoryEdu,
  LocalAtm,
  MilitaryTech,
  People,
  School,
  Settings,
  Star,
  Topic,
  Work,
} from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const navLinks = [
  {
    name: 'Users',
    Icon: People,
    link: '/users',
    index: 0,
    links: [
      {
        name: 'Students',
        Icon: People,
        link: '/users/students',
      },
      {
        name: 'Professors',
        Icon: People,
        link: '/users/professors',
      },
    ],
  },
  {
    name: 'SBT Points',
    Icon: MilitaryTech,
    link: '/points',
    index: 1,
    links: [
      {
        name: 'Points System',
        Icon: Settings,
        link: '/points/system',
      },
      {
        name: 'Ponts History',
        Icon: History,
        link: '/points/history',
      },
    ],
  },
  {
    name: 'Events',
    Icon: Work,
    link: '/events',
    index: 2,
    links: [
      {
        name: 'Lectures',
        Icon: School,
        link: '/events/lectures',
      },
      {
        name: 'Tutorings',
        Icon: HistoryEdu,
        link: '/events/tutorings',
      },
      {
        name: 'Events & Workshops',
        Icon: Event,
        link: '/events/other',
      },
    ],
  },
  {
    name: 'Resources',
    Icon: Feed,
    link: '/resources',
    index: 3,
    links: [
      {
        name: 'Financial Literacy',
        Icon: LocalAtm,
        link: '/resources/financial-literacy',
      },
      {
        name: 'Student Opportunities',
        Icon: Star,
        link: '/resources/student-opportunities',
        disabled: true,
      },
      {
        name: 'Institutional Resources',
        Icon: Topic,
        link: '/resources/institutional-resources',
        disabled: true,
      },
    ],
  },
  {
    name: 'Business Library',
    Icon: BusinessCenter,
    link: '/business-library',
    showInHome: true,
  },
  {
    name: 'Settings',
    Icon: Settings,
    link: '/settings',
    showInHome: true,
  },
];

export function NavLinks() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const onClick = (link: string) => () => navigate(link);
  const storedData = localStorage.getItem('navLinksOpened');
  const [opened, setOpened] = useState<boolean[]>(
    storedData ? (JSON.parse(storedData) as boolean[]) : [false, false],
  );
  const toggle = (index: number) => () => {
    setOpened(prev => {
      const newOpened = [...prev];
      newOpened[index] = !newOpened[index];
      localStorage.setItem('navLinksOpened', JSON.stringify(newOpened));
      return newOpened;
    });
  };

  return (
    <List
      disablePadding
      sx={{
        '.Mui-selected': {
          bgcolor: 'rgba(255, 100, 100, 0.2)',
        },
      }}
    >
      {navLinks.map(link => (
        <Fragment key={link.name}>
          <ListItemButton
            onClick={link.links ? toggle(link.index) : onClick(link.link)}
            selected={pathname.includes(link.link)}
            sx={{ px: 4, color: 'white' }}
          >
            <ListItemIcon>
              <link.Icon sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary={link.name} />
            {link.links &&
              (opened[link.index] ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
          {link.links && (
            <Collapse in={opened[link.index]} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                sx={{
                  '.Mui-selected': {
                    bgcolor: 'rgba(255, 100, 100, 0.4)!important',
                  },
                }}
              >
                {link.links.map(subLink => (
                  <ListItemButton
                    key={subLink.name}
                    onClick={onClick(subLink.link)}
                    selected={pathname.includes(subLink.link)}
                    sx={{ pl: 8, color: 'white' }}
                    disabled={subLink.disabled}
                  >
                    <ListItemIcon>
                      <subLink.Icon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText primary={subLink.name} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          )}
        </Fragment>
      ))}
    </List>
  );
}
