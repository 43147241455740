import { BreadCrumbs } from '@app/components/BreadCrumbs';
import { Layout } from '@app/components/Layout';
import { Grid } from '@mui/material';
import { ChangePassword } from './ChangePassword';
import { ProfileInfo } from './ProfileInfo';
import { UserOverview } from './UserOverview';

export function Profile() {
  return (
    <Layout>
      <BreadCrumbs
        links={[
          {
            label: 'Dashboard',
            link: '/',
          },
          {
            label: 'Profile',
            link: '/profile',
          },
        ]}
      />
      <UserOverview />
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <ProfileInfo />
        </Grid>
        <Grid item xs={12} md={4}>
          <ChangePassword />
        </Grid>
      </Grid>
    </Layout>
  );
}
