import { ConfirmDeleteModal } from '@app/components/ConfirmDeleteModal';
import { useModal } from '@app/components/GenericModal';
import { useAuth } from '@app/hooks/useAuth';
import {
  deletePointEntry,
  PointHistoryEntry,
} from '@app/services/pointService';
import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';

interface Props {
  data: PointHistoryEntry;
}

export function TableRowActions({ data }: Props) {
  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  const { tokenData } = useAuth();
  const token = tokenData?.token || '';

  return (
    <>
      <IconButton onClick={openDeleteModal}>
        <Delete color="error" />
      </IconButton>

      <ConfirmDeleteModal
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        text={data.student.firstName}
        deleteFn={() => deletePointEntry({ token, id: data.id })}
        queryKey="points-history"
      />
    </>
  );
}
