import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import { Login } from '@app/pages/Login';
import { Home } from '@app/pages/Home';

import { useAuth } from './hooks/useAuth';
import { Students } from './pages/Students';
import { Professors } from './pages/Professors';
import { Lectures } from './pages/Lectures';
import { Tutorings } from './pages/Tutorings';
import { Events } from './pages/Events';
import { PointSystem } from './pages/PointSystem';
import { Profile } from './pages/Profile';
import { ForgotPassword } from './pages/ForgotPassword';
import { ResetPassword } from './pages/ResetPassword';
import { PointsHistory } from './pages/PointsHistory';
import { FinacialLiteracy } from './pages/FinacialLiteracy';
import { BusinessLibrary } from './pages/BusinessLibrary';
import { Settings } from './pages/Settings';

function AuthedRoute() {
  const { isAuth } = useAuth();
  return isAuth ? <Outlet /> : <Navigate to="/login" />;
}

function NonAuthedRoute() {
  const { isAuth } = useAuth();
  return !isAuth ? <Outlet /> : <Navigate to="/" />;
}

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<NonAuthedRoute />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
        </Route>
        <Route element={<AuthedRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/users/students" element={<Students />} />
          <Route path="/users/professors" element={<Professors />} />
          <Route path="/events/lectures" element={<Lectures />} />
          <Route path="/events/tutorings" element={<Tutorings />} />
          <Route path="/events/other" element={<Events />} />
          <Route path="/points/system" element={<PointSystem />} />
          <Route path="/points/history" element={<PointsHistory />} />
          <Route
            path="/resources/financial-literacy"
            element={<FinacialLiteracy />}
          />
          <Route path="/business-library" element={<BusinessLibrary />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export { AppRoutes };
